import React, { useState } from "react";
import "./App.scss";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Dropzone } from "./components/Dropzone/Dropzone";
import { sendFormData } from "./utils/bx24";
import axios from "axios";

function App() {
  const [isFilled, setIsFilled] = useState(false);
  const [phone, setPhone] = useState("+380");
  const [isError, setIsError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [name, setName] = useState("");
  const [brigadeNumber, setBrigadeNumber] = useState("");
  const [isOnFrontLine, setIsOnFrontLine] = useState("");
  const [powerbankType, setPowerbankType] = useState("");
  const [numberOfPowerbanks, setNumberOfPowerbanks] = useState("");
  const [deliveryAddress, setDeliveryAddress] = useState("");
  const [novaPoshtaAddress, setNovaPoshtaAddress] = useState("");
  const [requestsAndWishes, setRequestsAndWishes] = useState("");
  const [typeOfCommunication, setTypeOfCommunication] = useState("");
  const [images, setImages] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const uploadPhotoInLiveFeed = async (dealId) => {
    const toBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });

    const promises = uploadedFiles.map((file) => toBase64(file));
    const base64Files = await Promise.all(promises);

    console.log("--->files<---", base64Files);
    console.log("--->dealId<---", dealId);

    try {
      const response = await axios.post(
        "http://localhost:3000/bitrix/api/uploadLiveFeedPhoto",
        {
          files: base64Files,
          dealId,
        }
      );

      console.log("Upload response:", response);
    } catch (error) {
      console.error("Error uploading files:", error);
    }
  };

  const submitFormHandle = async (event) => {
    event.preventDefault();
    setIsError(false);

    if (phone.trim().slice(0, 4) !== "+380") {
      setErrorText("*Номер телефону повинен починатися з +380");
      setIsError(true);
      return;
    }

    if (phone.length !== 13) {
      setErrorText("*Неправильний номер телефону");
      setIsError(true);
      return;
    }

    const formData = {
      phone,
      name,
      brigadeNumber,
      isOnFrontLine,
      powerbankType,
      numberOfPowerbanks,
      deliveryAddress,
      novaPoshtaAddress,
      requestsAndWishes,
      typeOfCommunication,
    };

    setIsFilled(true);

    const dealId = await sendFormData(formData);

    await uploadPhotoInLiveFeed(dealId);
  };

  return (
    <div className="App">
      <div className="main">
        <h1 className="title">Оформіть запит на бронювання павербанка</h1>
        <div className="bonus">
          {isFilled ? (
            <>
              <div className="divider"></div>
              <p className="message">
                Дякуємо. Ваша заявка буде оброблена протягом 24-ох годин.🐵❤️
              </p>
              <div className="divider"></div>
            </>
          ) : (
            <>
              <div className="bonus_instruction">
                <p className="bonus_instruction_item">
                  Компанія Vandal Vape уже виготовила понад 300 павербанків із
                  використаних одноразових подсистем і передала
                  військовослужбовцям для їх потреб.
                </p>

                <p className="bonus_instruction_item">
                  Ми виготовляємо 4 види павербанків:
                  <br />
                  1.Компактний в дерев’яному корпусі, ємність - 23 400 мАг
                  <br />
                  2.Компактний в дерев’яному корпусі, ємність - 27 200 мАг
                  <br />
                  3.Великий в дерев’яному корпусі, ємність - 38 400 мАг
                  <br />
                  4.Великий в пластиковому корпусі, ємність - 33 600 мАг
                </p>

                <p className="bonus_instruction_item">
                  Також, за потреби, беремося за виготовлення нестандартних
                  моделей з більшою кількістю акумуляторів та портів для
                  заряджання пристроїв.
                </p>

                <p className="bonus_instruction_item">
                  Для виготовлення одного павербанка потрібно від 28 до 38
                  відібраних та перевірених акумуляторів однакової ємності та
                  максимально схожих за внутрішнім опором. Ми розбираємо їх
                  сотнями, одразу перевіряємо та сортуємо.
                </p>

                <p className="bonus_instruction_item">
                  За місяць вдається виготовити близько 80 павербанків, але ми
                  працюємо над збільшенням цієї кількості. Тому на даний момент
                  ми можемо відправити лише до 3 павербанків на руки, для того,
                  щоб пришвидшити відправку ваших запитів
                </p>

                <p className="bonus_instruction_item">
                  Заповніть форму нижче, щоб ми могли з вами зв’язатись.
                  Першочергово оброблятимуться запити військовослужбовців на
                  передових лініях фронту.
                </p>
              </div>

              <div className="form_wrap">
                <form
                  className="form_form"
                  onSubmit={(e) => submitFormHandle(e)}
                  method="post"
                >
                  <div className="form">
                    <div className="form_input">
                      {isError && <p className="phoneError">{errorText}</p>}
                      <TextField
                        required
                        id="outlined-basic"
                        label="Номер телефону"
                        variant="outlined"
                        value={phone}
                        className="input_field"
                        onChange={(e) => {
                          setPhone(e.target.value);
                          setIsError(false);
                        }}
                        name="phone"
                      />
                    </div>

                    <div className="form_input">
                      <TextField
                        required
                        id="outlined-basic"
                        label="Ім’я та прізвище"
                        variant="outlined"
                        className="input_field"
                        name="link"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>

                    <div className="form_input">
                      <TextField
                        required
                        id="outlined-basic"
                        label="Номер бригади"
                        variant="outlined"
                        className="input_field"
                        name="link"
                        value={brigadeNumber}
                        onChange={(e) => setBrigadeNumber(e.target.value)}
                      />
                    </div>

                    <div className="form_input">
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Чи перебуваєте на передовій?
                        </InputLabel>

                        <Select
                          label="Чи перебуваєте на передовій?"
                          value={isOnFrontLine}
                          onChange={(e) => setIsOnFrontLine(e.target.value)}
                          required
                        >
                          <MenuItem value="Так">Так</MenuItem>
                          <MenuItem value="Ні">Ні</MenuItem>
                        </Select>
                      </FormControl>
                    </div>

                    <div className="form_input">
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Бажаний вид павербанку
                        </InputLabel>

                        <Select
                          label="Бажаний вид павербанку"
                          value={numberOfPowerbanks}
                          onChange={(e) =>
                            setNumberOfPowerbanks(e.target.value)
                          }
                          required
                        >
                          <MenuItem value="Компактний на 23400 мАг">
                            Компактний на 23400 мАг
                          </MenuItem>

                          <MenuItem value="Компактний на 27200 мАг">
                            Компактний на 27200 мАг
                          </MenuItem>

                          <MenuItem value="Великий на 23400 мАг">
                            Великий на 23400 мАг
                          </MenuItem>

                          <MenuItem value="Великий на 33600 мАг">
                            Великий на 33600 мАг
                          </MenuItem>

                          <MenuItem value="Нестандартний">
                            Нестандартний
                          </MenuItem>

                          <MenuItem value="Не важливо">Не важливо</MenuItem>
                        </Select>
                      </FormControl>
                    </div>

                    <div className="form_input">
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Бажана кількість павербанків
                        </InputLabel>

                        <Select
                          label="Бажана кількість павербанків"
                          value={powerbankType}
                          onChange={(e) => setPowerbankType(e.target.value)}
                          required
                        >
                          <MenuItem value="1">1</MenuItem>

                          <MenuItem value="2">2</MenuItem>

                          <MenuItem value="3">3</MenuItem>
                        </Select>
                      </FormControl>
                    </div>

                    <div className="form_input">
                      <TextField
                        required
                        id="outlined-basic"
                        label="Населений пункт"
                        variant="outlined"
                        className="input_field"
                        name="link"
                        value={deliveryAddress}
                        onChange={(e) => setDeliveryAddress(e.target.value)}
                      />
                    </div>

                    <div className="form_input">
                      <TextField
                        required
                        id="outlined-basic"
                        label="Номер та адреса відділення Нової пошти"
                        variant="outlined"
                        className="input_field"
                        name="link"
                        value={novaPoshtaAddress}
                        onChange={(e) => setNovaPoshtaAddress(e.target.value)}
                      />
                    </div>

                    <div className="form_input">
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Як вам краще з нами контактувати?
                        </InputLabel>

                        <Select
                          label="Як вам краще з нами контактувати?"
                          value={typeOfCommunication}
                          onChange={(e) =>
                            setTypeOfCommunication(e.target.value)
                          }
                          required
                        >
                          <MenuItem value="Дзвінок">Дзвінок</MenuItem>
                          <MenuItem value="Telegram">Telegram</MenuItem>
                          <MenuItem value="Viber">Viber</MenuItem>
                        </Select>
                      </FormControl>
                    </div>

                    <div className="form_input">
                      <TextField
                        id="outlined-basic"
                        label="Прохання та побажання"
                        variant="outlined"
                        className="input_field"
                        name="link"
                        value={requestsAndWishes}
                        onChange={(e) => setRequestsAndWishes(e.target.value)}
                      />
                      <p className="form_input_text">
                        Якщо ви раніше не спілкувались з нашою компанією в чаті
                        - перейдіть, будь ласка за посиланням на{" "}
                        <a
                          href="https://t.me/VandalVapeBot"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Telegram
                        </a>{" "}
                        або{" "}
                        <a
                          href="https://vandalvape.com.ua/r/viber"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Viber
                        </a>{" "}
                        і напишіть свій номер телефону, починаючи з +380 , щоб
                        ми могли вийти з вами на зв'язок. Дякуємо😊
                      </p>
                    </div>
                  </div>

                  {/*<div className="form_input_dropzone">*/}
                  {/*  <p className="form_input_text">*/}
                  {/*    Завантажте фото військового квитку:*/}
                  {/*  </p>*/}
                  {/*  <Dropzone*/}
                  {/*    images={images}*/}
                  {/*    setImages={setImages}*/}
                  {/*    uploadedFiles={uploadedFiles}*/}
                  {/*    setUploadedFiles={setUploadedFiles}*/}
                  {/*  />*/}
                  {/*</div>*/}

                  <div className="submit">
                    <Button
                      variant="contained"
                      className="form_button"
                      type="submit"
                      color="warning"
                    >
                      Відправити
                    </Button>
                  </div>
                </form>
              </div>
            </>
          )}
        </div>

        <div className="qna">
          <p className="qna_title">Питання - відповіді:</p>
          <Accordion className="accordion">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                Скільки часу потрібно очікувати на готовий павербанк?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Це залежить від пріоритетності вашого запиту. Першочергово
                оброблятимуться запити із передових ліній фронту.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion className="accordion">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography>Яка потужність?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Є 4 види ємності: на 23, 27, 33, 38 тисяч мАг.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion className="accordion">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography>
                Скільки пристроїв можна одночасно заряджати таким павербанком?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>2 пристрої</Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion className="accordion">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography>Для яких пристроїв підійде павербанк?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Для будь-яких пристроїв, які живляться напругою 5 Вольт
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>

      {/*<div className="footer"></div>*/}
    </div>
  );
}

export default App;
