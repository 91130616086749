import CryptoJS from "crypto-js";
import axios from "axios";

// Для надислання запиту на корезоїд
const secret = "X5bu8Sus6pdsks7enmtvTsYZxtvdaM3TKrWOZFcRZm9b0w7omg";
export const API_LOGIN = 125012;
export const getRequestTime = () => Math.floor(Date.now() / 1000);
export const getSignature = (data) => {
  const time = getRequestTime();
  const signature = CryptoJS.enc.Hex.stringify(
    CryptoJS.SHA1(time + secret + JSON.stringify(data) + secret)
  );
  return signature;
};

export const sendFormData = async (formData) => {
  // const data = {
  //   ops: [
  //     {
  //       type: "create",
  //       obj: "task",
  //       conv_id: 1325006,
  //       data: formData,
  //     },
  //   ],
  // };
  //
  // const time = getRequestTime();
  // const signature = getSignature(data);
  //
  // const url = `/api/1/json/${API_LOGIN}/${time}/${signature}`;
  //
  // try {
  //   const res = await axios.post(url, data);
  //
  //   return res.data.ops[0].data.dealId[0];
  // } catch (error) {
  //   console.log(error);
  // }

  const response = await axios.post(
    "https://testacademyserver.vandal.one/api/corezoid/submitPowerbankForm",
    formData
  );

  return response.data.ops[0].data.dealId[0];
};
